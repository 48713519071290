import { Box, FormControlLabel, InputLabel, Radio, RadioGroup, Slider, TextField, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

type PopulaRangeSliderPropsType = {
    title: string,
    min?: number,
    max?: number,
    value: number[],
    onChange: (_: any, values: any) => void
}
export const PopulaRangeSlider: FC<PopulaRangeSliderPropsType> = ({ title, min = 0, max = 99, value, onChange, ...props }): JSX.Element => {

    const id = title.toLowerCase().replace(' ', '-')
    const [currentMin, currentMax] = value

    return (
        <Box padding={'0.5rem'}>
            <Box>
                <span><b>{`${currentMin} - ${currentMax}`}</b></span>
            </Box>
            <Box paddingLeft={'7rem'}>
                <InputLabel htmlFor={id}>{title}</InputLabel>
                <Slider id={id} min={min} max={max} value={value} onChange={onChange} {...props} />
            </Box>
        </Box>
    )
}

type PopulaSliderPropsType = {
    title: string,
    min?: number,
    max?: number,
    value: number,
    onChange: (_: any, values: any) => void
}
export const PopulaSlider: FC<PopulaSliderPropsType> = ({ title, min = 0, max = 99, value, onChange, ...props }): JSX.Element => {

    const id = title.toLowerCase().replace(' ', '-')

    return (
        <Box padding={'0.5rem'}>
            <Box>
                <span><b>{`${value}`}</b></span>
            </Box>
            <Box paddingLeft={'7rem'}>
                <InputLabel htmlFor={id}>{title}</InputLabel>
                <Slider id={id} min={min} max={max} value={value} onChange={onChange} {...props} />
            </Box>
        </Box>
    )
}

type ScenarioNameInputPropsType = {
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export const ScenarioNameInput = ({ value, onChange }:ScenarioNameInputPropsType) => {

    const { t } = useTranslation()

    return (
        <Box padding={'0.5rem'}>
            <Box>
                <span><b>{t('general.changeName')}</b></span>
            </Box>
            <Box paddingLeft={'7rem'}>
                <TextField onChange={onChange} value={value} fullWidth size="small" id='scenario-name' required />
            </Box>
        </Box>
    )
}

type ScenarioChangeInputPropsType = {
    relative: boolean,
    onChange: (value:number) => void
}
export const ScenarioChangeInput = ({ onChange, relative }:ScenarioChangeInputPropsType) => {

    const { t } = useTranslation()

    const [ value, setValue ] = useState<string>('')

    const isLegalNumber = (value: string) => {
        return !isNaN(Number(value)) || (value.startsWith('-') && !isNaN(Number(value.slice(1))))
    }

    const checkAndSetValue = ({ target }:React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        const valueWithoutCommas = value.replace(',', '.')
        if (isLegalNumber(valueWithoutCommas)) {
            setValue(value)
            onChange(Number(valueWithoutCommas))
        }
    }

    return (
        <Box padding={'0.5rem'}>
            <Box>
                <span><b>{t('general.changeValue')}</b></span>
            </Box>
            <Box paddingLeft={'7rem'}>
                <TextField sx={{ width: '50px' }} onChange={checkAndSetValue} value={value} size="small" id='scenario-name' inputProps={{ size: 4, maxLength: 4 }} />
                { relative && <Typography component='span' variant='h6'> %</Typography> }
            </Box>
        </Box>
    )
}

type ScenarioChangeTypeInputPropsType = {
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    absoluteDisabled?: boolean
}
export const ScenarioChangeTypeInput = ({ value, onChange, absoluteDisabled = false }:ScenarioChangeTypeInputPropsType) => {

    const { t } = useTranslation()

    return (
        <Box padding={'0.5rem'}>
            <Box>
                <span><b>{t('general.changeType')}</b></span>
            </Box>
            <Box paddingLeft={'7rem'}>
                <RadioGroup row aria-label="scenario-change-type" name="scenario-change-type" value={value} onChange={onChange} defaultValue={'absolute'}>
                    <FormControlLabel value="percentage" control={<Radio />} label={t('general.relative')} />
                    <FormControlLabel value="absolute" control={<Radio />} label={t('general.absolute')} disabled={absoluteDisabled} />
                </RadioGroup>
            </Box>
        </Box>
    )
}
