import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import * as dfd from 'danfojs';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { requestPending } from '../../common/uiSlice';
import { ExportForecastButton } from '../../components/Buttons';
import { serializeDataframe } from './downloadComponentFunctions';

export type AreaAggregationType = Record<number, 'Kunta' | 'Suuralue' | 'Tilasto- tai pienalue'>

export const getAreaAggregation = (): AreaAggregationType => ({
    1: i18next.t('queryView.downloadComponent.municipality'),
    2: i18next.t('queryView.downloadComponent.majorArea'),
    3: i18next.t('queryView.downloadComponent.statisticalOrSmallArea'),
});

const generateOneYearAgeGroups = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (_, i) => ({
        from: start + i,
        to: start + i,
    }));

const generateFiveYearAgeGroups = (start: number, end: number) =>
    Array.from({ length: Math.floor((end - start) / 5) + 1 }, (_, i) => {
        const rangeStart = start + i * 5;
        const rangeEnd = rangeStart + 4;
        return {
            from: rangeStart,
            to: rangeEnd,
        };
    });

const getAgeGroupingMap = (): Record<string,{from:number,to:number}[]> => ({
    [i18next.t('queryView.downloadComponent.oneYearGroups')]: generateOneYearAgeGroups(0, 99),
    [i18next.t('queryView.downloadComponent.fiveYearGroups')]: generateFiveYearAgeGroups(0, 99),
    [i18next.t('queryView.downloadComponent.stageOfLife')]: [{from: 0, to: 0}, {from: 1, to: 2}, {from: 3, to: 6}, {from: 7, to: 12}, {from: 13, to: 15}, {from: 16, to: 18}, {from: 19, to: 24}, {from: 25, to: 64}, {from: 65, to: 74}, {from: 75, to: 84}, {from: 85, to: 99}],
    [i18next.t('queryView.downloadComponent.daycareAgeGroups')]: [{from: 0, to: 6}],
    [i18next.t('queryView.downloadComponent.youth')]: [{from: 0, to: 28}],
    [i18next.t('queryView.downloadComponent.allAgeGroups')]: [{from: 0, to: 99}],
});

const getAgeGroupingCaption = (ageGroups: {from: number, to: number}[]) => {
    const MAX_ITEMS = 10;
    const ageGroupStrings = ageGroups.map(({ from, to }) => from === to ? `${from}` : `${from}-${to}`);
    return ageGroupStrings.length <= MAX_ITEMS
        ? ageGroupStrings.join(', ')
        : `${ageGroupStrings.slice(0, MAX_ITEMS).join(', ')}, ...`;
};

type DownloadComponentPropsType = {
    data: dfd.DataFrame;
    areaNames: Record<string, string>;
    selectedAreas: string[];
    selectedAges: { from: number, to: number };
    // ageGroupingMap?: Record<string, { from: number; to: number }[]>; // e.g. 5-year age groups -> ['0-4', '5-9', ..]
};
export const DownloadComponent = ({ data, areaNames, selectedAreas, selectedAges }: DownloadComponentPropsType) => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const groupingNames = Object.keys(getAgeGroupingMap());
    const [selectedGrouping, setSelectedGrouping] = useState(groupingNames[0] || '');
    const [ selectedAreaDivision, setSelectedAreaDivision ] = useState(3)
    const [showOrientationOptions, setShowOrientationOptions] = useState(false);

    const [ download, setDownload ] = useState<string | null>(null)
    const [ worker, setWorker ] = useState<Worker | null>(null);

    const AREA_AGGREGATION = getAreaAggregation();
    const ageGroupingMap = getAgeGroupingMap();

    useEffect(() => {

        const worker = new Worker(new URL("worker.ts", import.meta.url))

        worker.onmessage = (event) => {
            console.log('worker finished')
            dispatch(requestPending(false))
            setDownload(event.data)
        }

        setWorker(worker)

        return () => {
            worker.terminate();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (!download) return

        // Add UTF-8 BOM to the start of the CSV data. Let's see if this helps with Excel's encoding issues.
        const bom = '\uFEFF';
        const blob = new Blob([bom + download], { type: 'text/csv;charset=utf-8;' });
    
        // Format the file name with the current timestamp and selected orientation
        const now = new Date();
        const formattedTimestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}`;
        const fileName = `export-${formattedTimestamp}.csv`;
    
        // Create a temporary link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownload(null)

    }, [download])

    const handleDownload = async (fileformat: 'excel' | 'csv', orientation: 'wide' | 'tall') => {

        if (fileformat === 'excel') {
            throw new Error('Excel format is currently not supported');
        }

        dispatch(requestPending(true))

        worker?.postMessage({ d: serializeDataframe(data), areaNames, selectedAreas, selectedAges, ageGroupingMap, selectedGrouping, orientation, areaDivision: selectedAreaDivision })
    };
        
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{ minWidth: 290, gap: 2, backgroundColor: '#C4E8FA', padding: 2, borderRadius: 1 }}
        >
            {/* Collapsible Header */}
            <Box
                display="flex"
                alignItems="center"
                onClick={() => setShowOrientationOptions(!showOrientationOptions)}
                sx={{ cursor: 'pointer', width: '100%', justifyContent: 'space-between' }}
            >
                <Box display="flex" alignItems="center">
                    <span className="material-icons-outlined" style={{ marginRight: 8 }}>download</span>
                    <Typography variant="h6" component="h6">
                        {t('queryView.downloadComponent.downloadSelectedData')}
                    </Typography>
                </Box>
                <span
                    className="material-icons-outlined"
                    style={{
                        transition: 'transform 0.3s ease',
                        transform: showOrientationOptions ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                >
                    expand_more
                </span>
            </Box>
    
            {/* Collapsible Content */}
            <Collapse in={showOrientationOptions} sx={{ width: '100%' }}>

            <ExportForecastButton title="Koko ennuste koneluettavassa muodossa" />
            
            <Select
                    fullWidth
                    value={selectedAreaDivision.valueOf()}
                    onChange={(e) => setSelectedAreaDivision(Number(e.target.value))}
                    displayEmpty
                    sx={{ width: '100%', mt: 2 }}
                >
                    {Object.entries(AREA_AGGREGATION).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            <Box>
                                <Typography variant="body1">{value}</Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>

                {/* Age Grouping Type Dropdown */}
                <Select
                    fullWidth
                    value={selectedGrouping}
                    onChange={(e) => setSelectedGrouping(e.target.value)}
                    displayEmpty
                    sx={{ width: '100%', mt: 2 }}
                >
                    {groupingNames.map((groupingName) => (
                        <MenuItem key={groupingName} value={groupingName}>
                            <Box>
                                <Typography variant="body1">{groupingName}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {getAgeGroupingCaption(ageGroupingMap[groupingName])}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
    
                {/* Download Options */}
                <Box display="flex" flexDirection="column" gap={1} mt={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleDownload('csv', 'wide')}
                        startIcon={<span className="material-icons-outlined">view_column</span>}
                        sx={{ justifyContent: 'flex-start', pl: 2 }}
                    >
                        {t('queryView.downloadComponent.csvWide')}
                    </Button>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleDownload('csv', 'tall')}
                        startIcon={<span className="material-icons-outlined">view_headline</span>}
                        sx={{ justifyContent: 'flex-start', pl: 2 }}
                    >
                        {t('queryView.downloadComponent.csvTall')}
                    </Button>
                </Box>
            </Collapse>
        </Box>
    );
    
};