import { BoxProps, Button, ButtonProps, Switch, SwitchProps } from "@mui/material";
import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { acquireToken } from "../common/functions";
import { isAuthor } from "../common/roleHelpers";
import { requestPending } from "../common/uiSlice";
import { baseUrl } from "../features/apis/apiSlice";
import { selectCurrentMunicipalityCode, selectCurrentUser } from "../features/apis/appSlice";
import { selectCurrentForecast } from "../features/apis/forecastApi";

type ButtonComponentPropsType = {
    onClick: () => void,
    title: string,
}
const ButtonComponent = ({ onClick, title }: ButtonComponentPropsType) => {
    return (
        <Button sx={{ alignSelf: 'right', color: 'black' }} variant="outlined" onClick={onClick}>
            <span className='material-icons-outlined'>download</span>
            {title}
        </Button>
    )
}

type ExportButtonPropsType = {
    url: string,
    title: string,
}
const ExportButton = ({ url, title }: ExportButtonPropsType) => {

    const dispatch = useAppDispatch()
    const municipalityCode = useAppSelector(selectCurrentMunicipalityCode)

    const onClick = async () => {

        const tokenResponse = await acquireToken()

        try {

            dispatch(requestPending(true))

            const response = await fetch(`${baseUrl}${url}`, {
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                    'municipality': municipalityCode
                }
            })

            dispatch(requestPending(false))

            const header = response.headers.get('Content-Disposition');
            const parts = header!.split(';');
            const filename = parts[1].split('=')[1];

            const blob = await response.blob()
            const href = URL.createObjectURL(blob)

            const link = document.createElement("a")

            link.download = filename
            link.href = href

            link.click()
            link.remove()
        } catch (error) {
            dispatch(requestPending(false))
            console.error(error)
        }
    }

    return (
        <ButtonComponent onClick={onClick} title={title} />
    )
}

export const ExportForecastButton = ({ title }: { title: string }) => {

    const { id } = useAppSelector(selectCurrentForecast)!

    return (
        // <ExportButton data={data} onClick={() => trigger({ baselineId: id! })} />
        <ExportButton url={`/projection/adjusted-data/forecast/${id}/download`} title={title} />
    )

}

const withAuthorisation = (Component: any) => ({ ...props }: any) => {
    const user = useAppSelector(selectCurrentUser) || {}
    return isAuthor(user) ? <Component {...props} /> : null
}

export const AuthoredButton = ({ ...props }: any) => {
    return withAuthorisation(Button)(props)
}

const withInactivatingLocking = (Component: React.FC) => ({ ...props }: ButtonProps | SwitchProps) => {
    const forecast = useAppSelector(selectCurrentForecast)
    return forecast?.locked ? <Component disabled={true} { ...props } /> : <Component { ...props } />
}

const withHidingLocking = (Component: React.FC) => ({ ...props }: ButtonProps | SwitchProps | BoxProps) => {
    const forecast = useAppSelector(selectCurrentForecast)
    return forecast?.locked ? null : <Component { ...props } />
}

export const LockActivatedButton = ({ ...props }: ButtonProps) => {
    return withInactivatingLocking(Button)(props)
}

export const LockHideButton = ({ ...props }: ButtonProps) => {
    return withHidingLocking(Button)(props)
}

export const LockableSwitch = ({ ...props }: SwitchProps) => {
    return withHidingLocking(Switch)(props)
}

export const LockAwareFragment = ({ ...props }: React.PropsWithChildren) => {
    return withHidingLocking(Fragment)(props)
}