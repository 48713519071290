import { Button, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { chooseParameter, closeDrawer, selectChosenParameter } from "../../common/uiSlice"
import { LockActivatedButton } from "../../components/Buttons"
import { PopulaRangeSlider, ScenarioChangeInput, ScenarioChangeTypeInput, ScenarioNameInput } from "../../components/ScenarioFormComponents"
import { ChangeType } from "../../types"
import { selectCurrentForecast, useUpdateForecastChangesMutation } from "../apis/forecastApi"

const EditMunicipalParameters = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const chosenParameter = useAppSelector(selectChosenParameter) || { parameterName: undefined, forecastParameter: undefined }
    const forecast = useAppSelector(selectCurrentForecast)
    
    const { fromYear, toYear } = forecast!

    const [ triggerUpdate ] = useUpdateForecastChangesMutation()

    const changeId = !(forecast?.changes || []).length ? 1 : Math.max(...forecast!.changes.map((change) => change.id)) + 1

    const closeView = () => {
        dispatch(closeDrawer())
        dispatch(chooseParameter(undefined))
    }

    const [ change, setChange ] = useState<ChangeType>({
        id: changeId,
        name: '',
        affectedParameters: [ chosenParameter!.parameterName! ],
        fromYear,
        toYear,
        fromAge: 0,
        toAge: 99,
        changeType: 'percentage',
        change: 0,
    })

    const submit = (event:any) => {
        event.preventDefault()
        triggerUpdate({ forecastId: forecast!.id!, changes: [ change ] })
        closeView()
    }

    const setYearValues = (values: number[]) => {
        const [ fromYear, toYear ] = values
        setChange({ ...change, fromYear, toYear })
    }

    const setAgeValues = (values: number[]) => {
        const [ fromAge, toAge ] = values
        setChange({ ...change, fromAge, toAge })
    }

    const setName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        setChange({ ...change, name: value })
    }

    const setChangeValue = (value:number) => {
        setChange({ ...change, change: value })
    }

    const setChangeType = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        setChange({ ...change, changeType: value as 'absolute' | 'percentage'})
    }

    return (
        <Stack>
            <Typography variant='h5'>
                {t('general.editParameter')} 
                <b> {t(`projectionParameter.${chosenParameter!.parameterName}`).toLowerCase()}</b>
            </Typography>
            <form>
                <Stack sx={{ margin: '1rem' }}>
                    <ScenarioNameInput value={change.name} onChange={setName} />
                    <PopulaRangeSlider title={t('general.years')} min={fromYear} max={toYear} value={[ change.fromYear, change.toYear ]} onChange={(_, value) => setYearValues(value as number[])} />
                    <PopulaRangeSlider title={t('general.ages')} min={0} max={99} value={[ change.fromAge, change.toAge ]} onChange={(e, value) => setAgeValues(value as number[])} />
                    <ScenarioChangeTypeInput value={change.changeType} onChange={setChangeType} absoluteDisabled={[ 'fertilityForecast', 'mortalityForecast' ].includes(chosenParameter!.parameterName!)} />
                    <ScenarioChangeInput onChange={setChangeValue} relative={change.changeType === 'percentage'} />
                </Stack>
                <Stack direction="row" spacing={1} width='100%' alignItems={'right'} padding={'0.5rem'}>
                    <Button variant="outlined" type="reset" onClick={() => closeView()}>{t('general.cancel')}</Button>
                    <LockActivatedButton variant="contained" type="submit" onClick={submit}>{t('general.apply')}</LockActivatedButton>
                </Stack>
            </form>
        </Stack>
    )
}

export default EditMunicipalParameters