import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Municipality, User } from "../../types";
import { parameterApiSlice } from "./projectionParametersSlice";

export type SelectableItem = { id: number, selected?: boolean }

export type ParameterSliceType = {
    forecast?: SelectableItem,
    currentMunicipality: Municipality,
    firstForecastYear: number,
    user: User,
}

export const appSlice = createSlice({
    name: "appSlice",
    initialState: {} as ParameterSliceType,
    reducers: {
        setMunicipality: (state, { payload }) => {
            state.currentMunicipality = payload
        },
        chooseForecast: (state, { payload }) => {
            if (payload) {
                const { id } = payload
                state.forecast = {
                    id,
                    selected: true
                }
            } else {
                state.forecast = undefined
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                parameterApiSlice.endpoints.getUserData.matchFulfilled,
                (state, { payload: user }) => {
                    if (user.municipalities?.length === 1) {
                        state.currentMunicipality = user.municipalities[0]
                    }
                    state.user = user;
                }
            ).addMatcher(
                parameterApiSlice.endpoints.getHistoryYears.matchFulfilled,
                (state, { payload: years }) => {
                    state.firstForecastYear = years[years.length - 1] + 1
                }
            )
    }
});

export const {
    setMunicipality,
    chooseForecast,
} = appSlice.actions

export const selectCurrentMunicipality = ({ appSlice }: RootState) => appSlice.currentMunicipality
export const selectCurrentMunicipalityCode = ({ appSlice }: RootState) => appSlice.currentMunicipality?.code
export const selectCurrentForecastId = ({ appSlice }: RootState) => appSlice.forecast

export const selectCurrentUser = ({ appSlice }: RootState) => appSlice.user