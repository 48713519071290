import { Box, Button, Card, CardActionArea, CardActions, CardContent, Popover, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import { AreaDivisionType, AreaForecastMethodType, ChangeType, ForecastType, MunicipalForecastMethodType } from '../types';
import { LockHideButton } from './Buttons';
import { BasicElasticTextField, ElasticYearSelect } from './Forms';
import { PopoverContentForForecast } from './PopoverContents';

type ForecastCardProps = {
    forecast: ForecastType,
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
    editMode: boolean,
    setEditMode: (edit: boolean) => void,
    edit: (input: { [key: string]: any }) => void,
    onSelect: () => void,
    selected: boolean,
    onCancel: () => void,
    onDelete?: (forecast: ForecastType) => void,
    onCopy?: (forecast: ForecastType) => void,
    locked: boolean,
    lock: (forecast: ForecastType) => void,
}
const ForecastCard = ({ forecast, onSubmit, editMode, setEditMode, edit, onSelect, selected, onCancel, onDelete, onCopy, locked, lock }: ForecastCardProps) => {

    const { t } = useTranslation()

    const { name, description, fromYear, toYear } = forecast
    const [hover, setHover] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Card
                component={'form'}
                variant={hover || selected ? 'outlined' : 'elevation'}
                onSubmit={onSubmit as any}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                onClick={onSelect}
                sx={{
                    margin: '1rem',
                    minWidth: '20rem',
                    maxWidth: '20rem',
                    backgroundColor: selected ? '#C4E8FA' : 'white',
                }}
            >
                <CardContent sx={{ padding: 0 }}>
                    <Stack>
                        <CardActionArea>
                            <Box sx={{ padding: '1rem', paddingRight: '2rem' }}>
                                <span className='material-icons-outlined' style={{ position: 'absolute', right: '1rem' }} onClick={handleClick}>info</span>
                                <BasicElasticTextField label={'name'} value={name} editMode={editMode} variant='h5' onUpdate={(e) => edit({ name: e.target.value })} />
                                <BasicElasticTextField label={'description'} value={description} editMode={editMode} onUpdate={(e) => edit({ description: e.target.value })} />
                                <ElasticYearSelect disabled={true} label={t('forecast.fromYear')} edit={editMode} value={fromYear} onUpdate={(e) => edit({ fromYear: e.target.value })} />
                                <ElasticYearSelect label={t('forecast.toYear')} edit={editMode} value={toYear} onUpdate={(e) => edit({ toYear: e.target.value })} />
                            </Box>
                        </CardActionArea>
                        <CardActions>
                            {
                                editMode ? (
                                    <Stack direction={'row'}>
                                        <Button variant="outlined" onClick={onCancel}>{t('general.cancel')}</Button>
                                        <Button variant="contained" type='submit'>{t('general.apply')}</Button>
                                    </Stack>
                                ) : (
                                    <Stack direction={'row'} justifyContent={'flex-end'}>
                                        <Box>
                                            <LockHideButton
                                                variant="outlined"
                                                onClick={() => setEditMode(true)}>
                                                <span className="material-icons-outlined">edit</span>
                                            </LockHideButton>
                                            <LockHideButton
                                                variant="outlined"
                                                onClick={() => onDelete!(forecast)}>
                                                <span className="material-icons-outlined">delete</span>
                                            </LockHideButton>
                                            {
                                                !!onCopy &&
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => onCopy!(forecast)}>
                                                    <span className="material-icons-outlined">content_copy</span>
                                                </Button>
                                            }
                                        </Box>
                                        {/*
                                            selected &&
                                            <ExportForecastButton />
                                        */}
                                        <Button
                                            variant={locked ? 'contained' : 'outlined'}
                                            onClick={() => lock(forecast)}>
                                            <span className="material-icons-outlined">{locked ? 'lock' : 'lock_open'}</span>
                                        </Button>
                                    </Stack>
                                )
                            }
                        </CardActions>
                    </Stack>
                </CardContent>
            </Card>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <PopoverContentForForecast forecast={forecast} />
            </Popover>
        </>

    )
}

type ForecastFormProps = {
    forecast?: ForecastType,
    onSubmit?: (forecast: ForecastType) => void,
    onSelect?: () => void,
    selected?: boolean,
    onClose?: () => void,
    onDelete?: (forecast: ForecastType) => void,
    onCopy?: (forecast: ForecastType) => void,
    lock: (forecast: ForecastType) => void,
}
export const ForecastForm = ({ forecast, onSubmit, onSelect, selected = false, onClose, onDelete, onCopy, lock }: ForecastFormProps) => {

    const firstForecastYear = useAppSelector(state => state.appSlice.firstForecastYear)

    const INITIAL_FORECAST = useMemo(() => ({
        name: '',
        description: '',
        fromYear: firstForecastYear,
        toYear: 2040,
        changes: [] as ChangeType[],
        municipalMethods: {} as MunicipalForecastMethodType,
        areaMethods: {} as AreaForecastMethodType,
        areaDivision: {} as AreaDivisionType,
    } as ForecastType), [firstForecastYear])
    
    const initialForecast = forecast || INITIAL_FORECAST

    const [originalForecast, setOriginalForecast] = useState<ForecastType>(initialForecast)
    const [edit, setEdit] = useState<boolean>(forecast ? false : true)

    const editForecast = (target: any) => {
        setOriginalForecast({ ...originalForecast, ...target })
    }

    const validateAndSend = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit!(originalForecast!)
        setEdit(false)
    }

    useEffect(() => {
        setOriginalForecast(forecast || INITIAL_FORECAST)
    }, [ forecast, INITIAL_FORECAST ])

    return (
        <ForecastCard
            selected={selected}
            onSelect={onSelect || (() => { })}
            forecast={originalForecast!}
            onSubmit={validateAndSend}
            editMode={edit}
            setEditMode={setEdit}
            edit={editForecast}
            onCancel={() => {
                if (!forecast) {
                    onClose!()
                } else {
                    setOriginalForecast(initialForecast)
                    setEdit(false)
                }
            }}
            onDelete={onDelete}
            onCopy={onCopy}
            locked={forecast?.locked || false}
            lock={lock}
        />
    )
}
