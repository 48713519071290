import { Button, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { PROJECTION_PARAMETERS } from "../../common/constants"
import { ParameterUIType, chooseParameter, closeDrawer } from "../../common/uiSlice"
import { LockActivatedButton } from "../../components/Buttons"
import ChooseHistoryYearsView from "../../components/ChooseHistoryYearsComponent"
import ChooseProjectionMethodView from "../../components/ChooseProjectionMethodView"
import { ProjectionParametersDiagram } from "../../components/ProjectionParameterDiagram"
import { ForecastType, ParameterMethodNameType, YearAndValueType } from '../../types'
import { selectCurrentMunicipalityCode } from "../apis/appSlice"
import { selectCurrentForecast, useUpdateMigrationForecastMutation } from "../apis/forecastApi"
import { selectParameterMethods, useGetFertilityForecastQuery, useGetFertilityHistoryQuery, useGetHistoryYearsQuery, useGetMigrationHistoryQuery, useGetMigrationPreviewForecastByTypeQuery, useGetMortalityForecastQuery } from "../apis/projectionParametersSlice"

type ParameterViewType = {
    data?: YearAndValueType[],
    historyData?: YearAndValueType[],
    parameter: ParameterUIType | undefined,
    currentHistoryYears?: number[],
    saveParameter: (parameter: ParameterUIType | undefined) => void,
    roundTo?: number,
}
const ParameterView = ({ data, historyData, currentHistoryYears, parameter, saveParameter, roundTo }: ParameterViewType) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [ historyYears, setHistoryYears ] = useState<number[]>(currentHistoryYears || [])
    const [ method, setMethod ] = useState<ParameterMethodNameType | undefined>(parameter?.forecastParameter.method)

    useEffect(() => {
        if (currentHistoryYears) {
            setHistoryYears(currentHistoryYears)
        }
    }, [currentHistoryYears])

    const { data: supportedMethods } = useAppSelector(selectParameterMethods)
    const methods = supportedMethods ? supportedMethods[parameter!.parameterName] : []

    const { data: availableHistoryYears } = useGetHistoryYearsQuery()

    const updatePreview = (p:ParameterUIType) => {
        dispatch(chooseParameter(p))
    }

    const updateHistoryYears = (years: number[]) => {
        const p = { ...parameter!, forecastParameter: { historyYears: years, method: method! } }
        setHistoryYears(years)
        updatePreview(p)
    }

    const updateMethod = (m: ParameterMethodNameType) => {
        const p = { ...parameter!, forecastParameter: { historyYears, method: m } }
        setMethod(m)
        updatePreview(p)
    }

    return (
        (data && parameter &&
            <Stack>
                {
                    parameter &&
                    <>
                        <ProjectionParametersDiagram
                            data={data}
                            history={historyData!}
                            label={t(PROJECTION_PARAMETERS.find(type => type.id === parameter?.parameterName)!.labelKey)}
                            roundTo={roundTo}
                        />

                        {
                            currentHistoryYears ?
                                <ChooseHistoryYearsView
                                    setHistoryYears={(years: number[]) => { updateHistoryYears(years) }}
                                    currentHistoryYears={historyYears}
                                    availableHistoryYears={availableHistoryYears}
                                />
                                : null
                        }
                        <ChooseProjectionMethodView
                            changeMethod={(m: ParameterMethodNameType) => { updateMethod(m) }}
                            method={method!}
                            supportedMethods={methods}
                        />
                    </>
                }
                <Stack direction="row" spacing={2} width='100%' alignItems={'right'}>
                    <Button variant={"outlined"} onClick={() => saveParameter(undefined)}>
                        {t('general.cancel')}
                    </Button>
                    <LockActivatedButton
                        variant="contained"
                        onClick={() => saveParameter({ ...parameter, forecastParameter: { historyYears, method: method! }} )}>
                            {t('general.apply')}
                    </LockActivatedButton>
                </Stack>
            </Stack>)
        || null
    )
}

type MigrationParameterViewType = {
    forecast: ForecastType,
    municipalityCode: string,
    parameter: ParameterUIType,
    wrapUpdate: () => void
}
const MigrationParameterView = ({ forecast, municipalityCode, parameter, wrapUpdate }: MigrationParameterViewType) => {

    const { fromYear, toYear } = forecast

    const { data } = useGetMigrationPreviewForecastByTypeQuery({
        fromYear,
        toYear,
        parameterName: parameter?.parameterName!,
        forecastMethod: parameter.forecastParameter,
    })
    
    const { history } = useGetMigrationHistoryQuery(
        municipalityCode,
        {
            selectFromResult: ({ data }) => ({
                history: data?.find((d) => d.type === parameter?.parameterName)?.data || [],
            })
        }
    )

    const [ update ] = useUpdateMigrationForecastMutation()

    const { meta: { historyYears } } = data || { meta: { historyYears: [] } }

    return (
        <ParameterView
            data={data?.data}
            historyData={history}
            currentHistoryYears={historyYears}
            parameter={parameter}
            saveParameter={(parameter: ParameterUIType | undefined) => {
                if (parameter) {
                    update({
                        forecast,
                        parameter: {
                            parameterName: parameter?.parameterName,
                            forecastMethod: parameter.forecastParameter,
                        } as any,
                    })
                }
                wrapUpdate()
            }}
        />
    )
}

const FertilityParameterView = ({ forecast, municipalityCode, parameter, wrapUpdate }: MigrationParameterViewType) => {

    const { fromYear, toYear } = forecast

    const { data } = useGetFertilityForecastQuery({
                        fromYear,
                        toYear,
                        parameterName: parameter?.parameterName!,
                        forecastMethod: parameter?.forecastParameter,
                    })
    
    const { data: history } = useGetFertilityHistoryQuery(municipalityCode)
    const { historyYears } = data?.meta || { historyYears: [] }

    const [ update ] = useUpdateMigrationForecastMutation()

    return (
        <ParameterView
            data={data?.aggregated.totalFertilityRates!}
            historyData={history?.aggregated.totalFertilityRates}
            currentHistoryYears={historyYears}
            parameter={parameter}
            saveParameter={(parameter: ParameterUIType | undefined) => {
                if (parameter) {
                    update({
                        forecast,
                        parameter: {
                            parameterName: parameter?.parameterName,
                            forecastMethod: parameter.forecastParameter,
                        } as any,
                    })
                }
                wrapUpdate()
            }
            }
            roundTo={2}
        />
    )
}

const MortalityParameterView = ({ forecast, parameter, wrapUpdate }: MigrationParameterViewType) => {

    const { fromYear, toYear } = forecast

    const { data } = useGetMortalityForecastQuery(
        {
            fromYear,
            toYear,
            parameterName: parameter?.parameterName!,
            forecastMethod: parameter?.forecastParameter,
        },
        {
            selectFromResult: (result) => {
                if (!result.isSuccess) return { data: undefined }
                const {data} = result
                return {
                data: Object.entries(data!.aggregated.lifeExpectancies).map(
                    ([year, value]) => ({
                        year: parseInt(year),
                        value: (value.f + value.m) / 2,
                    })
                )
                }
            }
        } 
    )    

    return (
        <ParameterView
            data={data}
            historyData={[]}
            parameter={parameter}
            saveParameter={(_: ParameterUIType | undefined) => { wrapUpdate() }}
        />
    )
}

const EditForecastParameterView = () => {

    const dispatch = useAppDispatch()

    const forecast = useAppSelector(selectCurrentForecast)!
    const municipalityCode = useAppSelector(selectCurrentMunicipalityCode)

    const chosenParameter = useAppSelector((state) => state.uiSlice.chosenParameter)

    const updateWrapper = () => {
            dispatch(chooseParameter(undefined))
            dispatch(closeDrawer())
        }

    if (!chosenParameter) {
        return null
    } else if (chosenParameter.parameterName === 'fertilityForecast') {
        return <FertilityParameterView forecast={forecast} municipalityCode={municipalityCode} parameter={chosenParameter!} wrapUpdate={updateWrapper} />
    } else if (chosenParameter.parameterName === 'mortalityForecast') {
        return <MortalityParameterView forecast={forecast} municipalityCode={municipalityCode} parameter={chosenParameter!} wrapUpdate={updateWrapper} />
    } else {
        return <MigrationParameterView forecast={forecast} municipalityCode={municipalityCode} parameter={chosenParameter!} wrapUpdate={updateWrapper} />
    }
}

export default EditForecastParameterView